/* eslint-disable import/no-mutable-exports */
import axios from 'axios'
import {
	CreateSigninRequestArgs,
	OidcClient,
	OidcClientSettings,
} from 'oidc-client-ts'
import { createContext } from 'react'
import { PlayerType } from '../enums'
import playBackendRequestHandler from './playBackendRequestHandler'

let oidcClientSettings: OidcClientSettings
let oidcClient: OidcClient
let createSigninRequestArgs: CreateSigninRequestArgs

export async function initOIDC() {
	if (!oidcClientSettings && !oidcClient && !createSigninRequestArgs) {
		const clientID = (
			(await playBackendRequestHandler('openidclient')).data as any
		).oclient_id
		// the following settings are for manual login
		oidcClientSettings = {
			authority: `${process.env.REACT_APP_DJANGO_SECDIM_ID}openid`,
			client_id: clientID, // need to get this in dev env somehow...
			redirect_uri: window.location.origin,
			post_logout_redirect_uri: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout`,
			response_type: 'code',
			scope: 'openid email profile',
			filterProtocolClaims: true,
			loadUserInfo: true,
		}
		createSigninRequestArgs = {
			redirect_uri: window.location.origin,
			response_type: 'code',
			scope: 'openid email profile',
		}
		oidcClient = new OidcClient(oidcClientSettings)
	}
}

// https://openid.net/specs/openid-connect-core-1_0.html
export function getTokens(): [string, string] {
	const accessToken = localStorage.getItem('accessToken')
	const refreshToken = localStorage.getItem('refreshToken')

	if (!accessToken || !refreshToken) {
		throw new Error('No Tokens')
	}

	return [accessToken!, refreshToken!]
}

export async function getUserInfo(): Promise<User> {
	return axios.get(`${process.env.REACT_APP_DJANGO_SECDIM_ID}openid/userinfo/`, {
		headers: {
			Authorization: `Bearer ${getTokens()[0]}`,
		},
	})
}

export function handleSignIn() {
	// Handle redirectURI in case logged in user is a company user
	// to allow them to be directed to /company
	const redirectURI = new URL(window.location.href)
	if (redirectURI.pathname.endsWith('secure-coding')) {
		redirectURI.pathname = ''
	}
	sessionStorage.setItem('redirectURI', redirectURI.toString())
	sessionStorage.removeItem('silentLoginAlreadyAttempted')
	oidcClient
		.createSigninRequest(createSigninRequestArgs)
		.then(req => {
			window.location.href = req.url // nosemgrep
		})
		.catch(err => {
			// eslint-disable-next-line no-console
			console.error(err)
		})
}

export const OpenIDContext = createContext({
	authenticated: null as boolean | null,
	profile: {
		firstName: '',
		lastName: '',
		username: '',
		email: '',
		guid: '',
		location: '',
		profileImage: '',
		dateJoined: '',
		lastActive: '',
		isSubscribed: false,
		subscriptionType: '',
		playerTotalGameScore: 0,
		playerType: PlayerType.General,
		company: {
			name: '',
			logo: '',
			department: {
				name: '',
				slug: '',
			},
		},
		restrictions: {
			subscribedAttempts: 0,
			subscribedRemainingAttempts: 0,
			hasPrivateGameAccess: false,
			hasPrivateChallengeAccess: false,
			hasPrivateHintAccess: false,
			hasBattleChallengeAccess: false,
			hasBattleChallengeSourceCodeAccess: false,
			hasCSEAccess: false,
			hasSandboxAccess: false,
			hasOpenSandboxPermission: false,
			hasAddSandboxPermission: false,
			hasDeleteSandboxPermission: false,
			hasAddUserPermission: false,
			hasViewCompanyDashboardPermission: false,
			hasAddCompanyUserAPIKeyPermission: false,
			hasRevokeCompanyUserAPIKeyPermission: false,
			hasAddCompanyGamePermission: false,
			hasAddCompanyDepartmentPermission: false,
		},
	},
	gameAndChallengeRestriction: {
		isGameUnrestricted: false,
		isChallengeUnrestricted: false,
	},
	refresh: () => {},
	isGlobalAlertShown: false,
	// eslint-disable-next-line no-unused-vars
	setIsGlobalAlertShown: (isGlobalAlertShown: boolean) => {},
	// eslint-disable-next-line no-unused-vars
	setGameAndChallengeRestriction: (gameAndChallengeRestriction: {
		isGameUnrestricted: boolean
		isChallengeUnrestricted: boolean
	}) => {},
})

export async function codeResponse(url: string) {
	await initOIDC()
	return oidcClient.processSigninResponse(url)
}

export function handleSignout() {}
