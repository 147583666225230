import { Add, Business, Info } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line import/prefer-default-export
export const TableBox = styled(Box)(({ theme }) => ({
	padding: '0 0',
	paddingRight: '0',

	'& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
		outline: 'none !important',
	},

	'& .MuiDataGrid-columnHeader:last-child': {
		'& .MuiDataGrid-columnSeparator': {
			display: 'none',
		},
	},

	'& .MuiDataGrid-footerContainer': {
		height: '40px !important',
		minHeight: '0px',
	},

	'& .css-7g6wwl-MuiInputBase-root-MuiTablePagination-select': {
		marginRight: '8px',
	},

	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},

	'& .MuiDataGrid-row': {
		cursor: 'pointer',
	},
}))

export const UserNavLink = styled(Button)({
	color: 'rgba(0, 0, 0, 0.87)',
	textDecoration: 'none',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
})

export const UserNavLinkText = styled(Typography)({
	marginLeft: '10px',
	marginTop: '0px',
	fontSize: '1rem',
})

export const HelpIcon = styled(Info)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: '13.5px',
	marginLeft: '5px',
	cursor: 'pointer',
}))

export const CustomAssignment = styled(Business)({
	fontSize: '23px',
})

export const CustomAdd = styled(Add)({
	fontSize: '30px',
})
